import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sanityConfig, imageBuilder } from "../client";
import "../styles/blog.css";

const BlogPostTemplate = () => {
  const [post, setPost] = useState(null);
  const { postId } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const query = `*[_type == "post" && _id == $postId][0]{
          ...,
          mainImage->{
            altText,
            "imageUrl": url
          },
          author->{
            name,
            image
          }
        }`;
        const params = { postId };

        const result = await sanityConfig.fetch(query, params);

        setPost(result);
      } catch (error) {
        console.error("Error fetching Sanity blog post:", error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const { title, body, mainImage, url, author } = post;

  const imageUrl = mainImage
    ? imageBuilder.image(mainImage).width(400).url()
    : null;

  return (
    <div className="blog-post margin-top-700">
      <hr className="divider"></hr>
      <h1 className="blog-title">{title}</h1>
      <hr className="divider"></hr>
      {imageUrl && <img src={imageUrl} alt={mainImage.altText} />}

      <div className="blog-content">
        {author && author.name && <p className="author">By {author.name}</p>}
        {body.map((block, index) => {
          if (block._type === "block") {
            const style = block.style;
            const children = block.children.map((child) => child.text).join("");

            switch (style) {
              case "h2":
                return (
                  <h2 className="heading2" key={index}>
                    {children}
                  </h2>
                );
              case "h3":
                return (
                  <h3 className="heading3" key={index}>
                    {children}
                  </h3>
                );
              case "h4":
                return (
                  <h4 className="heading4" key={index}>
                    {children}
                  </h4>
                );
              case "blockquote":
                return (
                  <blockquote className="blockquote" key={index}>
                    {children}
                  </blockquote>
                );
              case "normal":
              default:
                return (
                  <p className="paragraph" key={index}>
                    {children}
                  </p>
                );
            }
          } else if (block._type === "image") {
            const imageUrl = imageBuilder.image(block.asset).width(800).url();

            return <a href={imageUrl}><img src={imageUrl} alt={title} key={index} /></a>;
          }

          return null;
        })}
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            Visit the source
          </a>
        )}
      </div>
    </div>
  );
};

export default BlogPostTemplate;
