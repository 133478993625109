import React, { useEffect } from "react";
import AOS from "aos";
// import "aos/dist/aos.css";

import Header from "../components/Header";
import "../styles/about-us.css";
import "../styles/main.css";

function AboutUsPage() {
  useEffect(() => {
    AOS.init({
      duration: 800, // Duration of animation
      once: true, // Only animate once on scroll
      mirror: false, // Disable mirroring of animations
    });
  }, []);
  const title = "About Us";

  return (
    <>
      <Header title={title} />
      <section className="our-team | padding-block-700">
        <div className="container">
          <hr class="divider"/>
          <h2 class="text-center large-subheader aos-init aos-animate" data-aos="fade-up" data-aos-duration="600">Our Team</h2>
          <hr class="divider"/>
          <h6>
            The People Who Make It Happen: Introducing the Talented Team at RightClick Professional Services
          </h6>
          <div className="team | padding-block-500">
            <div className="team-member">
              <a href="https://www.linkedin.com/in/dgoldshore/" target="_blank">
                <img src="/images/headshots/David.jpg" alt="David" />
              </a>
              <h4>David Goldshore</h4>
              <p>President & Partner</p>
            </div>
            <div className="team-member">
              <a href="https://www.linkedin.com/in/bxyoung/" target="_blank">
                <img src="/images/headshots/Bill.jpg" alt="Bill"/>
              </a>
              <h4>Bill Young</h4>
              <p>CTO & Operating Partner</p>
            </div>
            <div className="team-member">
              <a href="https://www.linkedin.com/in/joepittman" target="_blank">
                <img src="/images/headshots/Joe.jpg" alt="Joe" />
              </a>
              <h4>Joe Pittman</h4>
              <p>Director of Service Delivery</p>
            </div>
            <div className="team-member">
              <a href="https://www.linkedin.com/in/mark-peffer-163534b/" target="_blank">
                <img src="/images/headshots/Mark.jpg" alt="Mark" />
              </a>
              <h4>Mark Peffer</h4>
              <p>Partner</p>
            </div>
            <div className="team-member">
              <a href="https://www.linkedin.com/in/brendan-adinolfi-a5b66829/" target="_blank">
                <img src="/images/headshots/Brendan.jpg" alt="Brendan" />
              </a>
              <h4>Brendan Adinolfi</h4>
              <p>Partner</p>
            </div>
            <div className="team-member">
              <a href="https://www.linkedin.com/in/sydneycendana/" target="_blank">
                <img src="/images/headshots/Sydney.jpg" alt="Sydney" />
              </a>
              <h4>Sydney Cendana</h4>
              <p>Business Operations</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUsPage;

/*
            <div className="team-member">
              <a href="https://www.linkedin.com/in/joepittman" target="_blank">
                <img src="/images/headshots/Joe.jpg" alt="Joe" />
              </a>
              <h4>Joe Pittman</h4>
              <p>Director of Service Delivery</p>
            </div>
*/
